@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 24px;
  margin-top: -120px;

  @media (--viewportMedium) {
    padding: 0;
  }

  & button {
    position: absolute;
  }

  [class*='popupSize'] {
    padding: 15px 0;
  }

  [class*='FieldTextInput_input'] {
    padding-left: 40px;
    padding-right: 30px;
  }

  [class*='clearButton'] {
    right: 33px;
    top: 31px;
    font-size: 0;

    @media (--viewportMedium) {
      right: 10px;
      top: 33px;
    }

    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 329.269 329' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cpath d='M194.8 164.77 323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0' fill='%23b0b0b5' opacity='1' data-original='%23000000' class=''%3E%3C/path%3E%3C/g%3E%3C/svg%3E");

      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  [class*='submitButton'] {
    left: 37px;
    top: 30px;
    margin-left: 0;
    font-size: 0;

    @media (--viewportMedium) {
      left: 10px;
      top: 32px;
    }

    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' xmlns='http://www.w3.org/2000/svg' fill='%2334adab'%3E%3Cg transform='matrix(-1 0 0 1 20 1)' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M13 14l5.241 5.241' stroke='%2334adab'%3E%3C/path%3E%3Ccircle cx='7.5' cy='7.5' r='7.5' stroke='%2334adab'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");

      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  [class*='cancelButton'] {
    display: none;
  }

  [class*='buttonsWrapper'] {
    padding: 0;
    margin: 0;
  }
}

.labelPlain {
  color: var(--colorGrey700);
}

.labelPlainSelected {
  color: var(--marketplaceColor);
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  composes: h4 from global;
  margin: 0;
  padding: 1px 0 0px 0;
  border: none;

  & label {
    display: none;
  }
}

.fieldPlain {
  composes: h4 from global;
  margin: 0;
  padding: 16px 0 30px 12px;
  border: none;

  @media (--viewportMedium) {
    padding: 16px 0 24px 12px;
  }
}

.fieldPlainLabel {
  padding: 4px 0 4px 0;
}
